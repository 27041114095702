import Swal from "sweetalert2";
import { confirmButtonColor } from "../../constants/ColourConstants";
import { getToken } from "../../api/utils/authToken";

const DeletePatientCard = async (dynamoId, autoDelete) => {
  const response = await fetch(
    `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/delete-patient-card`,
    {
      method: "POST",
      headers: {
        Accept: "*/*",
        Authorization: getToken(),
      },
      body: JSON.stringify(dynamoId),
    }
  );

  if (response.ok) {
    if (!autoDelete) {
      Swal.fire({
        title: "Patient deleted...",
        text: "",
        icon: "success",
        confirmButtonColor: confirmButtonColor,
      }).then(() => {
        window.location.reload();
      });
    }
  } else {
    if (!autoDelete) {
      Swal.fire({
        title: "Error",
        text: "Failed to delete patient.",
        icon: "error",
        confirmButtonColor: confirmButtonColor,
      });
    }
  }
};

export default DeletePatientCard;
