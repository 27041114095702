export const boxGrey = "#F2F4F6";
export const white = "#FFFFFF";
export const darkBlue = "#005470";
export const disableGrey = "#AEAEAE";
export const red = "#FF0000";
export const confirmButtonColor = "#005470";
export const black = "#000000";
export const grey = "#666666";
export const buttonHover = "#33768D";
export const borderGrey = "#E0E0E0";
