function ExtractMedicationsHistoryAllergies(paperChartNote) {
  let allergies = "";
  let patientNotes = "";
  // old format - "Medications: [meds] History: [history] Allergies: [allergies]"
  if (
    paperChartNote.includes("Medications:") &&
    paperChartNote.includes("History:") &&
    paperChartNote.includes("Allergies:")
  ) {
    allergies = paperChartNote
      .substring(paperChartNote.indexOf("Allergies:") + 10)
      .trim();

    patientNotes = paperChartNote
      .substring(
        paperChartNote.indexOf("Medications:") + 12,
        paperChartNote.indexOf("History:")
      )
      .trim()
      .concat(
        " ",
        paperChartNote
          .substring(
            paperChartNote.indexOf("History:") + 8,
            paperChartNote.indexOf("Allergies:")
          )
          .trim()
      )
      .trim();
  }
  // new format - ALLERGIES: [allergies]. NOTES: [notes]
  else if (
    paperChartNote.includes("ALLERGIES:") &&
    paperChartNote.includes("NOTES:")
  ) {
    allergies = paperChartNote
      .substring(
        paperChartNote.indexOf("ALLERGIES:") + 10,
        paperChartNote.indexOf(". NOTES:")
      )
      .trim();

    patientNotes = paperChartNote
      .substring(paperChartNote.indexOf(". NOTES:") + 8)
      .trim();
  }
  return { allergies, patientNotes };
}

export default ExtractMedicationsHistoryAllergies;
