import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./UserSlice";
import PatientAppointmentsSlice from "./PatientAppointmentsSlice";
import ProvidersSlice from "./ProvidersSlice";
import PharmaciesSlice from "./PharmaciesSlice";

const reducer = {
  user: userSlice,
  patientAppointments: PatientAppointmentsSlice,
  providers: ProvidersSlice,
  pharmacies: PharmaciesSlice,
};
const store = configureStore({
  reducer: reducer,
});

export default store;
