import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getContacts } from "../api/EndpointRequests";

const initialState = {
  allPharmacies: [],
};

export const fetchAllPharmacies = createAsyncThunk(
  "pharmacies/fetchAllPharmacies",
  async () => {
    const data = await getContacts();
    return data;
  }
);

export const pharmaciesSlice = createSlice({
  name: "pharmacies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllPharmacies.fulfilled, (state, action) => {
      state.allPharmacies = action.payload;
    });
  },
  setAllPharmacies: (state, action) => {
    state.allPharmacies = action.payload;
  },
});

export const selectPharmacies = (state) => state.pharmacies;
export const { setAllPharmacies } = pharmaciesSlice.actions;
export default pharmaciesSlice.reducer;
