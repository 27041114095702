import React, { useEffect, useState } from "react";

//Resources
import { getFeatureFlagByName } from "../api/FeatureFlagsEndpointRequests";

//MUI
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

const PatientBanner = () => {
  const [showBanner, setShowBanner] = useState();
  const [bannerText, setBannerText] = useState();

  useEffect(() => {
    const getFeatureFlag = async () => {
      const response = await getFeatureFlagByName("patientBannerText");
      setShowBanner(response.enabled);
      setBannerText(response.bannerText);
    };
    getFeatureFlag();
  }, []);

  return (
    showBanner && (
      <>
        <Alert severity="warning" sx={{ margin: 0 }}>
          <Typography align="center">{bannerText}</Typography>
        </Alert>
      </>
    )
  );
};

export default PatientBanner;
