import moment from "moment";

export const Slots = (date, reason, bookedAppointmentTimes, suggestions) => {
  /**
   * Separates the start and end time of appointments to 15 minute intervals.
   *
   * @param {number} startTime Start time of available appointments
   * @param {number} endTime End time of available appointments
   * @return {array} Appointments in intervals of 15 minutes
   */
  function calculateIntervals(startTime, endTime, date, providerId, subColumn) {
    const timeStops = [];
    let sTime = moment(startTime, "hmm");
    let eTime = moment(endTime, "hmm");

    let current = moment(sTime);

    while (current < eTime) {
      timeStops.push({
        time: current.format("h:mm a"),
        id: providerId,
        subColumn,
        date,
      });
      current.add(15, "minutes");
    }

    return timeStops;
  }

  /**
   * Finds the available slots and removes them based on booked appointments.
   *
   * @param {array} suggestions An array of appointment suggestions
   * @return {array} An array of found slots
   */
  const findSlots = (suggestions) => {
    let foundSlots = [];
    suggestions.forEach((suggestion) => {
      let temp = [];
      if (reason === "") {
        temp = calculateIntervals(
          suggestion.startTime,
          suggestion.endTime,
          suggestion.date,
          suggestion.providerId,
          suggestion.subColumn
        );
      } else if (
        suggestion.date.split("T")[0] === date &&
        suggestion.name === reason
      ) {
        temp = calculateIntervals(
          suggestion.startTime,
          suggestion.endTime,
          suggestion.date,
          suggestion.providerId,
          suggestion.subColumn
        );
      }

      temp = temp.filter((slot) => {
        const booked = bookedAppointmentTimes.find(
          (booked) =>
            calculateIntervals(booked.startTime, booked.endTime)[0].time ===
              slot.time &&
            booked.date.split("T")[0] === slot.date.split("T")[0] &&
            booked.providerId === slot.id &&
            booked.subColumn === slot.subColumn
        );
        return !booked;
      });
      temp.length > 0 && foundSlots.push(temp);
    });

    return foundSlots;
  };

  /**
   * Combines all found slots from all providers into one array.
   *
   * @param {array} foundSlots The found slots the database
   * @return {array} An array of combined slots
   */
  const combineSlots = (foundSlots) => {
    let combined = [];

    foundSlots.forEach((slots) => slots.forEach((slot) => combined.push(slot)));
    return combined;
  };

  /**
   * Removes duplicate times from all combined appointments.
   *
   * @param {array} combinedSlots The combined provider suggestion slots
   * @return {array} An array of unique slots
   */
  const removeDuplicates = (combinedSlots) => {
    let times = [];
    let ids = [];

    combinedSlots.forEach((slot) => {
      times.push(slot.time);
      ids.push(slot.id);
    });

    ids = Array.from(new Set(ids));
    times = Array.from(new Set(times));

    times.forEach((time) => {
      while (combinedSlots.filter((slot) => slot.time === time).length > 1) {
        const index = combinedSlots.findIndex((slot) => slot.time === time);
        if (
          combinedSlots[index] !== undefined &&
          combinedSlots.filter(
            // eslint-disable-next-line
            (slot) => slot.time === combinedSlots[index].time
          ).length > 1
        ) {
          combinedSlots = combinedSlots.filter(
            // eslint-disable-next-line
            (slot) => slot !== combinedSlots[index]
          );
        }
      }
    });

    return combinedSlots;
  };

  /**
   * Checks if the slot times have already past in the day.
   *
   * @param {array} removedDuplicates The array of unique slot times for the day
   * @return {array} An array of slots with the removed times that have passed
   */
  const checkPast = (removedDuplicates) => {
    const now = moment(new Date(), ["h:mm A"]).format("HH:mm");
    const todayFormatted = moment(new Date()).format("YYYY-MM-DD");

    if (moment(todayFormatted).isBefore(date)) return removedDuplicates;

    let temp = [];
    removedDuplicates.forEach((slot) => {
      const slotIn24 = moment(slot.time, ["h:mm A"]).format("HH:mm");
      const slotDate = moment(slot.date.split("T")[0]).format("YYYY-MM-DD");
      if (slotIn24 > now || slotDate > todayFormatted) temp.push(slot);
    });

    return removedDuplicates.filter((val) => temp.includes(val));
  };

  /**
   * Sorts the slots in order of earliest to latest time.
   *
   * @param {array} slots The array of removed slot times
   * @return {array} An array of sorted slot times
   */
  const sortSlots = (slots) => {
    const compare = (slot1, slot2) => {
      const time1 = moment(slot1.time, ["h:mm A"]).format("HH:mm");
      const time2 = moment(slot2.time, ["h:mm A"]).format("HH:mm");

      if (time1 < time2) return -1;
      if (time1 > time2) return 1;
      return 0;
    };
    slots.sort(compare);

    return slots;
  };

  const foundSlots = findSlots(suggestions);
  const combinedSlots = combineSlots(foundSlots);
  const removedDuplicates = removeDuplicates(combinedSlots);
  const removedPast = checkPast(removedDuplicates);

  sortSlots(removedPast);

  return removedPast;
};
