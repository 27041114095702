import { Button } from "@mui/material";
import React from "react";
import { darkBlue, white, buttonHover } from "./ColourConstants";

const CustomButton = ({ label, startIcon, onClick, sx }, disable = false) => {
  const style = {
    button: {
      color: white,
      backgroundColor: darkBlue,
      borderRadius: 2,
      marginTop: 3,
      paddingX: 1,
      "&:hover": {
        backgroundColor: buttonHover,
      },
      ...sx,
    },
  };
  return (
    <Button
      sx={style.button}
      onClick={onClick}
      startIcon={startIcon}
      disable={disable}
    >
      {label}
    </Button>
  );
};

export default CustomButton;
