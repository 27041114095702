import { Box, CircularProgress } from "@mui/material";
import React from "react";

const Loading = (props) => {
  const loading = props.loading;
  return (
    <Box
      sx={{
        m: 2,
        display: `${loading ? "flex" : "none"}`,
        width: "center",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
      }}
    >
      Checking for available slots...
      <CircularProgress color="inherit" size={16} />
    </Box>
  );
};

export default Loading;
