import { getToken } from "./utils/authToken";

/**
 * Puts the patient on the waitlist for the selected day.
 *
 * @param {object} requestBody Request data to be sent.
 */
export const scheduleWaitlist = async (requestBody) => {
  return await fetch(
    `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/schedule-waitList`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      body: JSON.stringify(requestBody),
    }
  ).then((response) => response.json());
};

/**
 * Get patient's waitlist status by patient id .
 *
 * @param {object} patientId Request data to be sent.
 */
export const fetchWaitlistByPatientID = async (patientId) => {
  const response = await fetch(
    `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/get-waitlist-by-patientID?patientId=${patientId}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        Authorization: getToken(),
      },
    }
  );
  const data = await response.json();
  return data ? data : [];
};

export const sendWaitlistMessage = async (body) => {
  await fetch(
    `https://${process.env.REACT_APP_ENV_MESSAGES_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/waitlist-msg`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    }
  );
};

/**
 * Update patient's waitlist status.
 *
 * @param {object} requestBody Request data to be sent.
 */
export const updateWaitlist = async (waitlistId, body) => {
  return await fetch(
    `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/update-patient-waitlist?waitlistId=${waitlistId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getToken(),
      },
      body: JSON.stringify(body),
    }
  ).then((response) => response.json());
};
