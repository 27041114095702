import React from "react";
import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { provinces } from "./provinceData";

const HealthCardForm = ({ formik, userData }) => {
  return (
    <Box component="div" sx={{ backgroundColor: "#f8f9f9" }}>
      <Grid container sx={{ mb: 2 }}>
        <FormControl sx={{ pl: 2, pt: 2 }}>
          <FormLabel id="health-card-info-label">
            Enter Health Card Information
          </FormLabel>
        </FormControl>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2, py: 2 }}>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            type="text"
            id="phin"
            name="phin"
            label="Health Card Number*"
            variant="filled"
            disabled={formik.values.noHealthCard}
            value={formik.values.phin ?? ""}
            onChange={(e) => formik.setFieldValue("phin", e.target.value)}
            error={formik.touched.phin && Boolean(formik.errors.phin)}
            helperText={formik.touched.phin && formik.errors.phin}
            inputProps={{
              style: {
                border: userData.regNumber ? "2px solid green" : "none",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            id="regNumber"
            name="regNumber"
            type="text"
            label="Registration Number"
            variant="filled"
            disabled={formik.values.noHealthCard}
            value={formik.values.regNumber}
            onChange={(e) => formik.setFieldValue("regNumber", e.target.value)}
            error={formik.touched.regNumber && Boolean(formik.errors.regNumber)}
            helperText={formik.touched.regNumber && formik.errors.regNumber}
            inputProps={{
              style: {
                border: userData.regNumber ? "2px solid green" : "none",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl
            fullWidth
            variant="filled"
            disabled={formik.values.noHealthCard}
          >
            <InputLabel
              id="health-province-select-label"
              error={
                formik.touched.healthCardProvince &&
                Boolean(formik.errors.healthCardProvince)
              }
            >
              Health Card Province*
            </InputLabel>
            <Select
              labelId="health-province-select-label"
              id="healthCardProvince"
              name="healthCardProvince"
              value={formik.values.healthCardProvince}
              onChange={formik.handleChange}
              error={
                formik.touched.healthCardProvince &&
                Boolean(formik.errors.healthCardProvince)
              }
            >
              {provinces.map((province) => (
                <MenuItem key={province} value={province}>
                  {province}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText
              error={
                formik.touched.healthCardProvince &&
                Boolean(formik.errors.healthCardProvince)
              }
            >
              {formik.touched.healthCardProvince &&
                formik.errors.healthCardProvince}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HealthCardForm;
