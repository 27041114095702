import moment from "moment";
export function formatTimeWithPeriod(timeString) {
  const number = parseInt(timeString); // Convert string to number
  const hours = Math.floor(number / 100);
  const minutes = number % 100;

  let period;
  let formattedHours;
  if (hours >= 12) {
    period = "PM";
    formattedHours = hours === 12 ? 12 : hours - 12;
  } else {
    period = "AM";
    formattedHours = hours === 0 ? 12 : hours;
  }

  const formattedTime = `${formattedHours}:${minutes
    .toString()
    .padStart(2, "0")} ${period}`;
  return formattedTime;
}

export function calculateRemainingTime(appointmentTime) {
  const currentTime = new Date();
  const appointmentDate = new Date();
  appointmentDate.setHours(Math.floor(appointmentTime / 100));
  appointmentDate.setMinutes(appointmentTime % 100);

  const remainingTimeInMilliseconds =
    appointmentDate.getTime() - currentTime.getTime();

  // Convert remaining time to hours and minutes
  const remainingHours = Math.floor(
    remainingTimeInMilliseconds / (1000 * 60 * 60)
  );
  const remainingMinutes = Math.floor(
    (remainingTimeInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
  );

  return {
    hours: remainingHours,
    minutes: remainingMinutes,
  };
}
export function combineDateAndTime(date, time) {
  if (time) {
    const hours =
      time.toString().length === 3
        ? parseInt(time.toString().substring(0, 1), 10)
        : parseInt(time.toString().substring(0, 2), 10);
    const minutes = parseInt(
      time.toString().substring(time.toString().length - 2),
      10
    );

    let dateAndTime = moment(date);
    dateAndTime = dateAndTime.set({
      hour: hours,
      minute: minutes,
      second: 0,
      millisecond: 0,
    });
    return dateAndTime.toDate();
  }
}
