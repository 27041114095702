// PatientCard.js
import React from "react";
import moment from "moment";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  IconButton,
  Divider,
  Alert,
  Tooltip,
  Grid,
} from "@mui/material";

import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { EventAvailable } from "@mui/icons-material";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

//CONSTANTS
import {
  white,
  darkBlue,
  red,
  black,
  disableGrey,
} from "../../constants/ColourConstants";

//COMPONENTS
import LockedCardsDialog from "../LockedCardsDialog";
import confirmCancellation from "../Waitlist/confirmCancellation";
import { combineDateAndTime } from "../Common/FormatTime";
import CustomButton from "../../constants/CustomButton";

const PatientCard = ({
  data,
  onDelete,
  todayAppointmentAlert,
  unpaidAppointmentsAlert,
  lockedCards,
  unpaidAppointments,
  paidAppointments,
  waitlistData,
  activeWaitListStatus,
}) => {
  const navigate = useNavigate();
  const today = moment().format("YYYY-MM-DD");
  const updatedData = {
    ...data,
    unpaidAppointments: unpaidAppointments,
    paidAppointments: paidAppointments,
  };
  const currentDateTime = moment();
  const appointmentDateEndTime = updatedData?.patientAppointments[0]?.endTime;
  const appointmentDate = updatedData?.patientAppointments[0]?.date;

  const hasTodayAppointment = todayAppointmentAlert.includes(
    updatedData?.patientId
  );
  const hasUnpaidAppointment = unpaidAppointmentsAlert.includes(
    updatedData?.patientId
  );
  const onWaitlist = activeWaitListStatus.includes(updatedData?.patientId);

  return (
    <Card
      variant="outlined"
      sx={{
        background: white,
        margin: "1rem",
        borderRadius: "10px",
        boxShadow: "3px 3px 3px",
        maxWidth: "300px",
        display: "inline-block",
      }}
    >
      {hasTodayAppointment &&
        combineDateAndTime(appointmentDate, appointmentDateEndTime) >
          currentDateTime && (
          <Alert severity="success" icon={<EventAvailable />}>
            <strong>Successfully booked an appointment!</strong>
            <br />
            Date and Time: <b>{updatedData.startTime}</b>
          </Alert>
        )}
      {onWaitlist && (
        <Alert severity="info" icon={<PendingActionsIcon />}>
          <strong>You're on the waitlist!</strong>
          <br />
          The clinic will call you once an appointment becomes available.
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              size="small"
              color="secondary"
              onClick={() => {
                const selectedWaitlistPatientId = waitlistData.filter(
                  (waitlist) => waitlist.patientId === updatedData?.patientId
                );
                confirmCancellation(
                  updatedData?.patientId,
                  selectedWaitlistPatientId[0].id,
                  selectedWaitlistPatientId[0].referralDate,
                  selectedWaitlistPatientId[0].requestDate,
                  today
                );
              }}
            >
              Cancel
            </Button>
          </div>
        </Alert>
      )}

      {hasUnpaidAppointment && (
        <Alert severity="error">
          <strong>No-show fee required!</strong>
          <br />
          Please call <a href="tel:431-222-2273">431-222-2273</a> for missed
          appointment.
        </Alert>
      )}
      <CardContent>
        <Grid
          sx={{
            display: "flex",
            alignItems: "stretch",
            justifyContent: "space-between",
            padding: "1px",
          }}
        >
          <Grid>
            <Typography
              variant="h6"
              component="div"
              color={darkBlue}
              sx={{
                fontSize: "large",
              }}
            >
              {updatedData?.demographics?.firstName}{" "}
              {updatedData?.demographics?.lastName}
            </Typography>
          </Grid>
          <Grid sx={{ display: "inline" }}>
            <Tooltip enterTouchDelay={50} title="Edit" placement="top">
              <IconButton
                color="info"
                size="small"
                sx={{
                  color: black,
                }}
                onClick={() => {
                  navigate("/editPatient", {
                    state: updatedData,
                  });
                }}
              >
                <BorderColorIcon />
              </IconButton>
            </Tooltip>
            <Tooltip enterTouchDelay={50} title="Delete" placement="top">
              <IconButton
                onClick={() =>
                  onDelete(
                    updatedData?.dynamoId,
                    hasTodayAppointment || hasUnpaidAppointment,
                    onWaitlist
                  )
                }
              >
                <DeleteIcon
                  color="default"
                  sx={{
                    color: red,
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Divider />
        <Typography
          sx={{ textAlign: "left", marginBottom: "10px", marginTop: "20px" }}
          variant="body2"
        >
          <b>DOB: </b>
          {moment(updatedData?.demographics?.birthday).format("ll")}
        </Typography>
        <Typography
          variant="body2"
          sx={{ textAlign: "left", marginBottom: "10px" }}
        >
          <b>PHIN: </b>
          {updatedData?.demographics?.healthCard.phn ?? "No Health Card"}
        </Typography>
        <Typography
          sx={{ textAlign: "left", marginBottom: "7px" }}
          variant="body2"
        >
          <b>Phone: </b>
          {updatedData?.demographics?.phones.filter(
            (item) => item.contactType === "CellPhone"
          )[0]?.number ??
            updatedData?.demographics?.phones.filter(
              (item) => item.contactType === "HomePhone"
            )[0]?.number}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px",
        }}
      >
        <CustomButton
          label="Past Appointment"
          sx={{ fontSize: 12 }}
          onClick={async () => {
            navigate("/pastAppointments", {
              state: updatedData,
            });
          }}
        />
        {hasUnpaidAppointment ? (
          <LockedCardsDialog />
        ) : hasTodayAppointment ? (
          <Tooltip
            enterTouchDelay={50}
            title="We currently allow one appointment per day"
          >
            <span>
              <CustomButton
                label={
                  combineDateAndTime(appointmentDate, appointmentDateEndTime) >
                  currentDateTime
                    ? "Appointment Booked"
                    : "Appointment Completed"
                }
                disabled
                startIcon={<EventAvailableIcon />}
                sx={{
                  backgroundColor: disableGrey,
                  fontSize: 12,
                  "&:hover": {
                    backgroundColor: disableGrey,
                  },
                  ml: 1,
                }}
              />
            </span>
          </Tooltip>
        ) : (
          <CustomButton
            label="Book Appointment"
            sx={{ fontSize: 12 }}
            disabled={lockedCards}
            onClick={() => {
              navigate("/patientBooking", {
                state: updatedData,
              });
            }}
          />
        )}
      </CardActions>
    </Card>
  );
};

export default PatientCard;
