// Material UI
import {
  Autocomplete,
  Box,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  LocalizationProvider,
  MobileDatePicker,
  PickersDay,
  pickersDayClasses,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";

// React
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";
import { selectUser } from "../Store/UserSlice";
import { selectProviders } from "../Store/ProvidersSlice";
import { selectPharmacies } from "../Store/PharmaciesSlice";
import {
  confirmButtonColor,
  darkBlue,
  boxGrey,
  borderGrey,
} from "../constants/ColourConstants";
//components
import CustomButton from "../constants/CustomButton";
import HealthCardForm from "./PatientRegistration/healthCardForm";
import { provinceLengths, provinces } from "./PatientRegistration/provinceData";
import { getToken } from "../api/utils/authToken";

const style = {
  typographyHeader: {
    color: darkBlue,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Arial",
    textAlign: "center",
  },
};

const CreatePatientForm = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const contacts = useSelector(selectPharmacies);
  const userData = useSelector(selectUser);
  const doctors = useSelector(selectProviders);
  let navigate = useNavigate();

  useEffect(() => {
    // Sets the user data.
    async function callPatientAPI() {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
    }
    callPatientAPI();
  }, []);

  async function checkPatientExist(values) {
    const data = await fetch(
      `https://${
        process.env.REACT_APP_ENV_ACCURO_GATEWAY
      }.execute-api.ca-central-1.amazonaws.com/${
        process.env.REACT_APP_ENV
      }/search-patients?phn=${values.phin}&dob=${JSON.stringify(
        values.dateOfBirth
      )}&regNumber=${values.regNumber}&userId=${user.attributes["sub"]}`,
      {
        method: "GET",
        headers: {
          Accept: "*/*",
          Authorization: getToken(),
        },
      }
    ).then((response) => {
      return response.json();
    });
    return data;
  }

  /**
   * Sends an create patient request to Accuro based on form values.
   *
   * @param {object} body Request data to be sent.
   * @return {boolean} Request result.
   */
  async function addPatient(body) {
    setLoading(true);
    const checkPatientExists = await checkPatientExist(body);
    if (checkPatientExists.length > 0) {
      Swal.fire({
        title: "Patient already exists",
        text: "This patient has an active profile. Please search for the patient using the correct health card number and date of birth before creating a new profile, or try again with different information.",
        icon: "warning",
        confirmButtonColor: confirmButtonColor,
        confirmButtonText: "Return to Home",
        showDenyButton: true,
        denyButtonColor: confirmButtonColor,
        denyButtonText: `Try Again`,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        }
      });
    } else {
      const data = await fetch(
        `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/add-patients`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getToken(),
          },
          body: JSON.stringify(body),
        }
      ).then((response) => {
        return response.json();
      });
      if (!data.error && data.message !== "Internal server error") {
        await addPatientCard({
          patientId: data,
          phin: body.phin,
          firstName: body.firstName,
          lastName: body.lastName,
          userId: user.attributes["sub"],
          userEmail: user.attributes["email"],
        });
        Swal.fire({
          title: "Patient Added",
          text: "You have Added the patient.",
          icon: "success",
          confirmButtonColor: confirmButtonColor,
        });
        navigate("/");
      } else {
        Swal.fire({
          title: "Oops! Please try again!",
          text: data.error ?? data.message,
          icon: "error",
          confirmButtonColor: confirmButtonColor,
        });
      }
    }

    setLoading(false);
  }

  /**
   * Creates a patient card in the Dynamo database.
   *
   * @param {object} requestBody Request data to be sent.
   */
  async function addPatientCard(requestBody) {
    await fetch(
      `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/save-data`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        body: JSON.stringify(requestBody),
      }
    ).then((response) => {
      return response.json();
    });
  }

  const validationSchema = Yup.object().shape(
    {
      firstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      lastName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Required"),
      middleName: Yup.string().max(20, "Must be 20 characters or less"),
      dateOfBirth: Yup.string().required("Required"),
      sex: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
      city: Yup.string().required("Required"),
      postalCode: Yup.string()
        .matches(
          /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
          "Invalid postal code"
        )
        .required("Required"),
      province: Yup.string().required("Required"),
      phoneNumber: Yup.string()
        .matches(
          /^(\+?1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})?$/,
          "Invalid phone number"
        )
        .min(10, "Must be 10 digits")
        .max(10, "Must be 10 digits")
        .when("landlineNumber", {
          is: (landlineNumber) => !landlineNumber,
          then: Yup.string().required(
            "At least one method of contact is required."
          ),
        }),
      landlineNumber: Yup.string()
        .matches(
          /^(\+?1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})?$/,
          "Invalid landline number"
        )
        .min(10, "Must be 10 digits")
        .max(10, "Must be 10 digits")
        .when("phoneNumber", {
          is: (phoneNumber) => !phoneNumber,
          then: Yup.string().required(
            "At least one method of contact is required."
          ),
        }),
      noHealthCard: Yup.boolean(),
      phin: Yup.string()
        .when("healthCardProvince", {
          is: (province) => !!province && !!provinceLengths[province],
          then: Yup.string()
            .matches(/^[a-zA-Z0-9]+$/, "Must be number or alphanumeric")
            .test(
              "exactLength",
              "Your health card number must be exactly the specified length for the selected province",
              function (value) {
                const province = this.parent.healthCardProvince;
                return value?.length === provinceLengths[province];
              }
            ),
        })
        .required("Required"),
      healthCardProvince: Yup.string().when("noHealthCard", {
        is: false,
        then: Yup.string().required("Required"),
      }),
      regNumber: Yup.string()
        .matches(/^[a-zA-Z0-9]+$/, "Must be number or alphanumeric")
        .when("healthCardProvince", {
          is: "MB",
          then: Yup.string().required("Required"),
        }),
      allergies: Yup.string().max(100, "Must be less than 100 characters"),
      patientNotes: Yup.string().max(100, "Must be less than 100 characters"),
    },
    [
      ["phoneNumber", "landlineNumber"],
      ["landlineNumber", "phoneNumber"],
    ]
  );

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      middleName: "",
      dateOfBirth: userData.dob ? JSON.parse(userData.dob) : "",
      sex: "",
      address: "",
      city: "",
      postalCode: "",
      province: "",
      phoneNumber: "",
      landlineNumber: "",
      email: "",
      noHealthCard: userData.healthCard ? false : true,
      phin: userData.phin ? userData.phin : "",
      regNumber: userData.regNumber ? userData.regNumber : "",
      healthCardProvince: userData.healthCardProvince
        ? userData.healthCardProvince
        : "",
      pharmacy: "",
      doctor: "",
      allergies: "",
      patientNotes: "",
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      await addPatient(values);
    },
  });

  const sexes = ["Male", "Female", "Other"];

  return (
    <>
      {doctors.allProviders && contacts.allPharmacies ? (
        <>
          <div>
            <Container maxWidth="lg">
              <Box
                sx={{
                  m: 3,
                  p: 3,
                  background: boxGrey,
                  borderRadius: "16px",
                  borderStyle: "solid",
                  borderColor: borderGrey,
                  borderWidth: "1px",
                }}
              >
                <Typography sx={style.typographyHeader}>
                  Patient Registration
                </Typography>
                <Box component="form" onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="firstName"
                        name="firstName"
                        type="text"
                        variant="filled"
                        label="First Name*"
                        value={formik.values.firstName}
                        onChange={(e) => {
                          const capitalizedValue =
                            e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1);
                          formik.handleChange(e);
                          formik.setFieldValue("firstName", capitalizedValue); //Manually set the capitalized value
                        }}
                        error={
                          formik.touched.firstName &&
                          Boolean(formik.errors.firstName)
                        }
                        helperText={
                          formik.touched.firstName && formik.errors.firstName
                        }
                        // Highlight the field if the initial value is not empty
                        inputProps={{
                          style: {
                            border: userData.firstName
                              ? "2px solid green"
                              : "none",
                          },
                          maxLength: 15,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="lastName"
                        name="lastName"
                        type="text"
                        label="Last Name*"
                        variant="filled"
                        value={formik.values.lastName}
                        onChange={(e) => {
                          const capitalizedValue =
                            e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1);
                          formik.handleChange(e);
                          formik.setFieldValue("lastName", capitalizedValue); //Manually set the capitalized value
                        }}
                        error={
                          formik.touched.lastName &&
                          Boolean(formik.errors.lastName)
                        }
                        helperText={
                          formik.touched.lastName && formik.errors.lastName
                        }
                        inputProps={{
                          style: {
                            border: userData.lastName
                              ? "2px solid green"
                              : "none",
                          },
                          maxLength: 20,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        fullWidth
                      >
                        <MobileDatePicker
                          label="Date of Birth*"
                          disableFuture
                          TextFieldProps={{ variant: "filled" }}
                          id="dateOfBirth"
                          name="dateOfBirth"
                          labelId="date-of-birth-select-label"
                          inputFormat="YYYY-MM-DD"
                          value={formik.values.dateOfBirth}
                          onChange={(value) =>
                            formik.setFieldValue("dateOfBirth", value)
                          }
                          openTo="year"
                          views={["year", "month", "day"]}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              error={
                                formik.touched.dateOfBirth &&
                                Boolean(formik.errors.dateOfBirth)
                              }
                              helperText={
                                formik.touched.dateOfBirth &&
                                formik.errors.dateOfBirth
                              }
                            />
                          )}
                          renderDay={(day, _value, DayComponentProps) => {
                            return (
                              <PickersDay
                                {...DayComponentProps}
                                sx={{
                                  [`&&.${pickersDayClasses.selected}`]: {
                                    backgroundColor: "#005470",
                                  },
                                }}
                              />
                            );
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth variant="filled">
                        <InputLabel
                          id="sex-select-label"
                          error={
                            formik.touched.sex && Boolean(formik.errors.sex)
                          }
                        >
                          Sex*
                        </InputLabel>
                        <Select
                          fullWidth
                          id="sex"
                          labelId="sex-select-label"
                          name="sex"
                          variant="filled"
                          value={formik.values.sex}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.sex && Boolean(formik.errors.sex)
                          }
                        >
                          {sexes.map((sex, i) => (
                            <MenuItem key={i + 1} value={i + 1}>
                              {sex}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText
                          error={
                            formik.touched.sex && Boolean(formik.errors.sex)
                          }
                        >
                          {formik.touched.sex && formik.errors.sex}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="address"
                        name="address"
                        type="text"
                        label="Address*"
                        variant="filled"
                        value={formik.values.address}
                        onChange={(e) => {
                          const capitalizedValue =
                            e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1);
                          formik.handleChange(e);
                          formik.setFieldValue("address", capitalizedValue); //Manually set the capitalized value
                        }}
                        error={
                          formik.touched.address &&
                          Boolean(formik.errors.address)
                        }
                        helperText={
                          formik.touched.address && formik.errors.address
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="city"
                        name="city"
                        type="text"
                        label="City*"
                        variant="filled"
                        value={formik.values.city}
                        onChange={(e) => {
                          const capitalizedValue =
                            e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1);
                          formik.handleChange(e);
                          formik.setFieldValue("city", capitalizedValue); //Manually set the capitalized value
                        }}
                        error={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                        helperText={formik.touched.city && formik.errors.city}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="postalCode"
                        name="postalCode"
                        type="text"
                        label="Postal Code*"
                        variant="filled"
                        value={formik.values.postalCode}
                        onChange={(e) => {
                          const capitalizedValue =
                            e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1);
                          formik.handleChange(e);
                          formik.setFieldValue("postalCode", capitalizedValue); //Manually set the capitalized value
                        }}
                        error={
                          formik.touched.postalCode &&
                          Boolean(formik.errors.postalCode)
                        }
                        helperText={
                          formik.touched.postalCode && formik.errors.postalCode
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth variant="filled">
                        <InputLabel
                          id="province-select-label"
                          error={
                            formik.touched.province &&
                            Boolean(formik.errors.province)
                          }
                        >
                          Province*
                        </InputLabel>
                        <Select
                          labelId="province-select-label"
                          id="province"
                          name="province"
                          value={formik.values.province}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.province &&
                            Boolean(formik.errors.province)
                          }
                        >
                          {provinces.map((province) => (
                            <MenuItem key={province} value={province}>
                              {province}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText
                          error={
                            formik.touched.province &&
                            Boolean(formik.errors.province)
                          }
                        >
                          {formik.touched.province && formik.errors.province}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="phoneNumber"
                        name="phoneNumber"
                        type="text"
                        label="Best Cell Phone Number to Contact You"
                        variant="filled"
                        value={formik.values.phoneNumber}
                        onChange={(e) =>
                          /^[0-9]{0,10}$/.test(e.target.value) &&
                          formik.setFieldValue("phoneNumber", e.target.value)
                        }
                        error={
                          formik.touched.phoneNumber &&
                          Boolean(formik.errors.phoneNumber)
                        }
                        helperText={
                          formik.touched.phoneNumber &&
                          formik.errors.phoneNumber
                        }
                        inputProps={{
                          maxLength: 10,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        type="text"
                        label="Best Email to Contact You"
                        variant="filled"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="landlineNumber"
                        name="landlineNumber"
                        type="text"
                        label="Landline Number"
                        variant="filled"
                        value={formik.values.landlineNumber}
                        onChange={(e) =>
                          /^[0-9]{0,10}$/.test(e.target.value) &&
                          formik.setFieldValue("landlineNumber", e.target.value)
                        }
                        error={
                          formik.touched.landlineNumber &&
                          Boolean(formik.errors.landlineNumber)
                        }
                        helperText={
                          formik.touched.landlineNumber &&
                          formik.errors.landlineNumber
                        }
                        inputProps={{
                          maxLength: 10,
                        }}
                      />
                      <InputLabel
                        sx={{
                          color: "#da271e",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        *Landline numbers will not receive confirmation text.*
                      </InputLabel>
                    </Grid>
                  </Grid>
                  <HealthCardForm
                    formik={formik}
                    provinces={provinces}
                    userData={userData}
                  />

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        options={contacts.allPharmacies}
                        getOptionLabel={(option) =>
                          option.length === 0 ? "" : option.contactName
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.contactId === value?.contactId
                        }
                        value={
                          formik.values.pharmacy !== "" &&
                          formik.values.pharmacy !== null
                            ? contacts.allPharmacies.find((pharmacy) => {
                                return (
                                  pharmacy.contactId === formik.values.pharmacy
                                );
                              })
                            : null
                        }
                        onChange={(e, value) =>
                          formik.setFieldValue(
                            "pharmacy",
                            value ? value.contactId : null
                          )
                        }
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.contactId}>
                            {option.contactName}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Preferred Pharmacy"
                            variant="filled"
                            fullWidth
                          />
                        )}
                        disabled={contacts.allPharmacies.length === 0}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        options={doctors.uniqueProviders}
                        getOptionLabel={(option) =>
                          option.length === 0 ? "" : option.fullName
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        value={
                          formik.values.doctor !== "" &&
                          formik.values.doctor !== null
                            ? doctors.uniqueProviders.find((doctor) => {
                                return doctor.id === formik.values.doctor;
                              })
                            : null
                        }
                        onChange={(e, value) =>
                          formik.setFieldValue(
                            "doctor",
                            value ? value.id : null
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Family Doctor"
                            variant="filled"
                            fullWidth
                          />
                        )}
                        disabled={doctors.uniqueProviders.length === 0}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        id="allergies"
                        name="allergies"
                        type="text"
                        label="Allergies"
                        variant="filled"
                        multiline
                        rows={2}
                        value={formik.values.allergies}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.allergies &&
                          Boolean(formik.errors.allergies)
                        }
                        helperText={
                          formik.touched.allergies && formik.errors.allergies
                        }
                        inputProps={{
                          maxLength: 100,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        id="patientNotes"
                        name="patientNotes"
                        type="text"
                        label="Notes (Include any relevant medications and medical history)"
                        placeholder="Include any relevant medications and medical history"
                        variant="filled"
                        multiline
                        rows={3}
                        value={formik.values.patientNotes}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.patientNotes &&
                          Boolean(formik.errors.patientNotes)
                        }
                        helperText={
                          formik.touched.patientNotes &&
                          formik.errors.patientNotes
                        }
                        inputProps={{
                          maxLength: 100,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid alignItems="center">
                    <CustomButton
                      sx={{ marginX: 2 }}
                      onClick={formik.handleSubmit}
                      label="Save"
                    ></CustomButton>

                    <CustomButton
                      label="Cancel"
                      onClick={() => {
                        navigate("/");
                      }}
                    ></CustomButton>
                  </Grid>
                </Box>
              </Box>
            </Container>
          </div>
        </>
      ) : (
        <>
          <div className="App">
            <Box
              sx={{
                display: `${loading ? "flex" : "none"}`,
                width: "center",
                height: 600,
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          </div>
        </>
      )}
    </>
  );
};
export default CreatePatientForm;
