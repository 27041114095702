// Amplify
import React, { useState } from "react";
import { Auth } from "aws-amplify";

// Material UI
import {
  Box,
  Container,
  FilledInput,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// Constants
import {
  borderGrey,
  boxGrey,
  confirmButtonColor,
  darkBlue,
  red,
} from "../constants/ColourConstants";
import CustomButton from "../constants/CustomButton";

// Frameworks
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";

import { useNavigate } from "react-router-dom";

const style = {
  box: {
    m: 3,
    p: 3,
    background: boxGrey,
    borderRadius: "16px",
    borderStyle: "solid",
    borderColor: borderGrey,
    borderWidth: "1px",
  },
  typographyHeader: {
    color: darkBlue,
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "Arial",
    textAlign: "center",
    mb: 2,
  },
};

const initalVisibility = [false, false, false];

const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState(initalVisibility);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    currentPassword: Yup.string()
      .required("Required")
      .min(8, "Must be at least 8 characters long"),
    newPassword: Yup.string()
      .required("Required")
      .min(8, "Must be at least 8 characters long"),
    confirmPassword: Yup.string()
      .required("Required")
      .min(8, "Must be at least 8 characters long")
      .oneOf(
        [Yup.ref("newPassword")],
        "Confirm password should match new password"
      ),
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleChangePassword(values);
    },
  });

  const handleClickShowPassword = (index) => {
    const newShowPassword = [...showPassword];
    newShowPassword[index] = !newShowPassword[index];
    setShowPassword(newShowPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangePassword = async (values) => {
    try {
      setErrorMessage("");
      await Auth.currentAuthenticatedUser().then((user) => {
        return Auth.changePassword(
          user,
          values.currentPassword,
          values.newPassword
        );
      });
      Swal.fire({
        title: "Password changed successfully",
        icon: "success",
        confirmButtonColor: confirmButtonColor,
        confirmButtonText: "Return to Home",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        }
      });
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <Container maxWidth="md" sx={{ height: "100%", mt: 5 }}>
      <Box boxShadow={10} sx={style.box}>
        <Typography id="change-password" sx={style.typographyHeader}>
          CHANGE PASSWORD
        </Typography>

        {errorMessage && <Grid sx={{ color: red }}>{errorMessage}</Grid>}

        <form className="form" onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="filled">
                <InputLabel id="currentPassword-label">
                  Current Password*
                </InputLabel>
                <FilledInput
                  id="currentPassword"
                  name="currentPassword"
                  type={showPassword[0] ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          handleClickShowPassword(0);
                        }}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword[0] ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
                <FormHelperText
                  error={
                    formik.touched.currentPassword &&
                    Boolean(formik.errors.currentPassword)
                  }
                >
                  {formik.touched.currentPassword &&
                    formik.errors.currentPassword}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="filled">
                <InputLabel id="newPassword-label">New Password*</InputLabel>
                <FilledInput
                  id="newPassword"
                  name="newPassword"
                  type={showPassword[1] ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          handleClickShowPassword(1);
                        }}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword[1] ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
                <FormHelperText
                  error={
                    formik.touched.newPassword &&
                    Boolean(formik.errors.newPassword)
                  }
                >
                  {formik.touched.newPassword && formik.errors.newPassword}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="filled">
                <InputLabel id="confirmPassword-label">
                  Confirm Password*
                </InputLabel>
                <FilledInput
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showPassword[2] ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          handleClickShowPassword(2);
                        }}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword[2] ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
                <FormHelperText
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                >
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid alignItems="center">
            <CustomButton
              sx={{ marginX: 2 }}
              onClick={formik.handleSubmit}
              label="Change Password"
            />
            <CustomButton
              sx={{ marginX: 2 }}
              onClick={() => {
                navigate("/");
              }}
              label="Cancel"
            />
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default ChangePassword;
