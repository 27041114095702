// Amplify
import { Amplify, Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

//Frameworks
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

//Redux
import { useDispatch } from "react-redux";
import { fetchAllProviders } from "../Store/ProvidersSlice";
import { fetchAllPharmacies } from "../Store/PharmaciesSlice";

//MUI
import {
  Alert,
  Box,
  Container,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CircularProgress from "@mui/material/CircularProgress";

// Components
import { formatTimeWithPeriod } from "./Common/FormatTime";
import { fetchWaitlistByPatientID } from "../api/WaitlistEndpointRequests";
import {
  appointmentsHistory,
  getPatientAppointments,
} from "../api/AppointmentEndpointRequests";
import PatientCard from "./PatientCards/patientCards";
import DeletePatientCard from "./PatientCards/deletePatientCard";
import config from "../aws-exports";
import CustomButton from "../constants/CustomButton";

// Constants and styles
import img6 from "./../images/image6.jpg";
import img5 from "./../images/Image5.jpg";
import {
  confirmButtonColor,
  darkBlue,
  grey,
  boxGrey,
  borderGrey,
} from "../constants/ColourConstants";
import { getToken, setToken } from "../api/utils/authToken";

Amplify.configure(config);
function Home() {
  const [patientData, setPatientData] = useState([]);
  const [unpaidAppointmentsAlert, setUnpaidAppointmentsAlert] = useState([]);
  const [unpaidAppointments, setUnpaidAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lockedCards, setLockedCards] = useState(false);
  const [todayAppointmentAlert, setTodayAppointmentAlert] = useState([]);
  const [activeWaitListStatus, setActiveWaitListStatus] = useState([]);
  const [waitlistData, setWaitlistData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const today = moment(new Date()).format("YYYY-MM-DD");
  const tomorrow = moment(today).add(1, "day").format("YYYY-MM-DD");
  const [paidAppointments, setPaidAppointments] = useState([]);
  const [accuroDeletePatient, setAccuroDeletePatient] = useState(false);

  const style = {
    typographyBody: {
      color: grey,
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "Arial",
      textAlign: "center",
    },
    typographyButton: {
      color: darkBlue,
      fontSize: 16,
      fontWeight: "bold",
      fontFamily: "Arial",
      textAlign: "center",
    },
  };

  useEffect(() => {
    const getPatientCards = async () => {
      setLoading(true);
      const patients = await getPatientsByUserId();
      await loadPatientCards(patients);
      setLoading(false);
    };

    const fetchData = async () => {
      await getPatientCards();
      dispatch(fetchAllProviders());
      dispatch(fetchAllPharmacies());
    };

    fetchData();
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    patientData.sort((a, b) => {
      return (
        a.demographics.firstName.localeCompare(b.demographics.firstName) ||
        a.demographics.lastName.localeCompare(b.demographics.lastName)
      );
    });
  }, [patientData]);

  const getPatientsByUserId = async () => {
    const user = await Auth.currentAuthenticatedUser();
    setToken(user.signInUserSession.idToken.jwtToken);
    const userId = user.attributes["sub"];
    const patients = await fetch(
      `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/user-info?userId=${userId}`,
      {
        method: "GET",
        headers: {
          Accept: "*/*",
          Authorization: getToken(),
        },
      }
    ).then((response) => response.json());
    return patients.Items;
  };

  const loadPatientCards = async (data) => {
    await Promise.all(
      data.map(async (element) => {
        await getPatientData(element["id"], element["patientId"]);
        await getWaitlistByPatientID(element["patientId"]);
      })
    );
  };

  async function onDelete(dynamoId, hasPendingAppt, onWaitlist) {
    if (hasPendingAppt || onWaitlist) {
      Swal.fire({
        title: "Cannot delete patient card",
        text: hasPendingAppt
          ? "This patient has incomplete or unpaid appointments."
          : "This patient is currently on the waitlist. Please cancel the waitlist before deleting the patient card.",
        icon: "error",
        confirmButtonColor: confirmButtonColor,
      });
    } else {
      const result = await Swal.fire({
        title: "Are you sure you want to delete this patient?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
        confirmButtonColor: confirmButtonColor,
      });
      if (result.isConfirmed) {
        DeletePatientCard(dynamoId, false);
      }
    }
  }

  async function getPatientData(id, patientId) {
    const response = await fetch(
      `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/get-patient-by-id?patientId=${patientId}&dynamoId=${id}`,
      {
        method: "GET",
        headers: {
          Accept: "*/*",
          Authorization: getToken(),
        },
      }
    );

    const data = await response.json();
    if (!response.ok || !data) {
      return;
    }
    if (data.error === "PATIENT_NOT_FOUND") {
      setAccuroDeletePatient(true);
      DeletePatientCard(id, true);
      return;
    }

    const patientAppointments = await getPatientAppointments(patientId);
    data.dynamoId = id;
    data.startTime = await checkTodayAppointment(
      patientAppointments,
      patientId
    );
    data.patientAppointments = patientAppointments;

    setPatientData((prev) => [...prev, data]);

    if (patientAppointments.length > 0) {
      await getAppointmentsHistory(
        patientAppointments[0]?.appointmentId,
        patientId
      );
    }
  }

  async function getAppointmentsHistory(appointmentId, patientID) {
    setLockedCards(true);
    const data = await appointmentsHistory(appointmentId);
    if (!data.error && data.message !== "Internal server error") {
      const appointmentNotPaid = data.filter(
        (appointment) =>
          appointment.action_reason === "Not Paid" &&
          appointment.action_type === "CustomStatusAdded"
      );
      const appointmentPaid = data.filter(
        (appointment) =>
          appointment.action_reason === "Not Paid" &&
          appointment.action_type === "CustomStatusRemoved"
      );

      if (appointmentNotPaid.length > 0 && appointmentPaid.length === 0) {
        setUnpaidAppointments((prev) => [...prev, appointmentId]);
        setUnpaidAppointmentsAlert((prev) => [...prev, patientID]);
      } else if (appointmentPaid.length > 0) {
        setPaidAppointments((prev) => [...prev, appointmentId]);
      }
    }
    setLockedCards(false);
  }

  async function checkTodayAppointment(patientAppointments, patientId) {
    if (patientAppointments.length > 0) {
      const todayAppointments = patientAppointments.filter(
        (appointment) =>
          moment(appointment.date).isSame(today, "day") ||
          moment(appointment.date).isSame(tomorrow, "day")
      );
      if (todayAppointments.length > 0) {
        setTodayAppointmentAlert((prev) => [...prev, patientId]);

        const appointmentDate = moment(todayAppointments[0].date).format(
          "dddd, MMMM Do YYYY"
        );
        const appointmentTime = formatTimeWithPeriod(
          todayAppointments[0].startTime
        );
        return `${appointmentDate} @ ${appointmentTime}`;
      }
    }
    return null;
  }
  async function getWaitlistByPatientID(patientId) {
    const data = await fetchWaitlistByPatientID(patientId);
    if (data.contents && data.contents.length > 0) {
      const activeWaitlist = data.contents.filter(
        (waitlist) => waitlist.caseState === "Active"
      );
      if (activeWaitlist.length > 0) {
        setWaitlistData((prevData) => [...prevData, ...activeWaitlist]);
        setActiveWaitListStatus((prev) => [...prev, patientId]);
      }
    }
  }

  const handleClose = () => {
    setAccuroDeletePatient(false);
  };

  return (
    <Authenticator>
      <div>
        <div className="App">
          <Grid
            container
            sx={{
              backgroundColor: "transparent",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              marginTop: 1,
              marginBottom: 1,
            }}
            rowSpacing={3}
            columnSpacing={3}
          >
            <Grid item xs={12} md={6}>
              <Typography
                variant="h2"
                sx={{
                  color: darkBlue,
                  fontSize: 20,
                  fontWeight: "bold",
                  fontFamily: "Arial",
                  textAlign: "center",
                }}
              >
                SAME DAY OR NEXT DAY APPOINTMENTS
              </Typography>
              <br />
              <Container>
                <Typography variant="h2" sx={style.typographyBody}>
                  <b>Once you have booked an appointment:</b> You will see a
                  confirmation message on your patient card below. If you have
                  provided a cell phone number, you will also receive a TEXT
                  confirmation. Please call the clinic at 431-222-2273 for any
                  inquiries regarding your appointment.
                </Typography>
                <br />
                <Typography variant="h2" sx={style.typographyBody}>
                  If no appointments are available, you can choose to join the
                  waitlist for today between 6 AM and 6 PM.
                  <Grid>
                    <CustomButton
                      onClick={() => {
                        navigate("/startForm");
                      }}
                      startIcon={<PersonAddIcon />}
                      label="Create New Patient Profile"
                    ></CustomButton>
                  </Grid>
                  <Grid>
                    <CustomButton
                      onClick={() => {
                        document
                          .getElementById("registered-patients")
                          .scrollIntoView({ behavior: "smooth" });
                      }}
                      label="View Registered Patients"
                    />
                  </Grid>
                </Typography>
              </Container>
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                style={{
                  borderRadius: 10,
                }}
                alt="Home 1"
                src={img6}
                height="70%"
                width="70%"
                align="center"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <img
                style={{
                  borderRadius: 10,
                }}
                alt="Home 2"
                src={img5}
                height="70%"
                width="70%"
                align="center"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={style.typographyButton}>
                REFERRAL PATIENTS
              </Typography>
              <Typography variant="h5" sx={style.typographyBody}>
                <p>
                  If a provider has referred you to see us for further care
                  please call the clinic instead of booking an online
                  appointment. Referral time slots are not available for online
                  booking. If you are a referral patient upon arrival, your
                  appointment will be rescheduled.
                </p>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            {accuroDeletePatient && (
              <Snackbar
                open={accuroDeletePatient}
                autoHideDuration={10000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  <Grid item xs={12}>
                    <strong sx={{ padding: "20%" }}>
                      {`Failed to fetch data for patient`}
                    </strong>
                  </Grid>
                  Unable to retrieve patient information. Please try creating a
                  new patient profile for any missing patient cards.
                </Alert>
              </Snackbar>
            )}
          </Grid>
          <Box
            sx={{
              m: 3,
              p: 3,
              background: boxGrey,
              borderRadius: "16px",
              borderStyle: "solid",
              borderColor: borderGrey,
              borderWidth: "1px",
            }}
          >
            <Typography
              id="registered-patients"
              variant="h2"
              sx={style.typographyButton}
            >
              REGISTERED PATIENTS
            </Typography>
            {!loading ? (
              <Grid
                container
                spacing={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 4,
                  ml: "auto",
                  mr: "auto",
                }}
                width="100%"
              >
                {patientData.length ? (
                  patientData.length !== 0 &&
                  patientData.map((data, i) => (
                    <PatientCard
                      key={i}
                      data={data}
                      onDelete={onDelete}
                      todayAppointmentAlert={todayAppointmentAlert}
                      unpaidAppointmentsAlert={unpaidAppointmentsAlert}
                      lockedCards={lockedCards}
                      tomorrow={tomorrow}
                      unpaidAppointments={unpaidAppointments}
                      paidAppointments={paidAppointments}
                      waitlistData={waitlistData}
                      activeWaitListStatus={activeWaitListStatus}
                    />
                  ))
                ) : (
                  <div>
                    <Typography variant="h6" sx={{ flexGlow: 1 }}>
                      No Patients Available
                    </Typography>
                    <Typography variant="body1" sx={{ flexGlow: 1 }}>
                      Click on Create New Patient Account button to register a
                      patient.
                    </Typography>
                  </div>
                )}
              </Grid>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                Loading Patients ...
                <CircularProgress />
              </Box>
            )}
          </Box>
        </div>
      </div>
    </Authenticator>
  );
}

export default Home;
