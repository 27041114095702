import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getProviders, uniqueProviders } from "../api/EndpointRequests";

const initialState = {
  allProviders: [],
  uniqueProviders: [],
  loading: false,
};

export const fetchAllProviders = createAsyncThunk(
  "providers/fetchAllProviders",
  async () => {
    const data = await getProviders();
    return data;
  }
);

export const providersSlice = createSlice({
  name: "providers",
  initialState,

  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllProviders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllProviders.fulfilled, (state, action) => {
      state.loading = false;
      state.allProviders = action.payload;
      state.uniqueProviders = action.payload.filter(uniqueProviders);
    });
  },
  setAllProviders: (state, action) => {
    state.allProviders = action.payload;
  },
});

export const selectProviders = (state) => state.providers;
export const selectProvidersLoading = (state) => state.providers.loading;
export const { setAllProviders } = providersSlice.actions;
export default providersSlice.reducer;
