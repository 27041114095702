// Redux
import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    setUserData: (state, action) => {
      return action.payload;
    },
  },
});

export const selectUser = (state) => state.user;

export const { setUserData } = userSlice.actions;

export default userSlice.reducer;
