import { updateWaitlist } from "../../api/WaitlistEndpointRequests";

const handleCancelWaitlist = async (
  patientId,
  waitlistId,
  referralDate,
  requestDate,
  caseCloseDate
) => {
  const body = {
    id: waitlistId,
    waitlistProviderId: process.env.REACT_APP_ENV_WAITLIST_PROVIDER_ID,
    patientId: patientId,
    consultPriority: process.env.REACT_APP_ENV_CONSULT_PRIORITY,
    consultType: "Inpatient",
    referralDate: referralDate,
    requestDate: requestDate,
    caseCloseDate: caseCloseDate,
    caseState: "Closed",
    consultStatus: "Cancelled",
    notes: "Patient canceled the waitlist request from the booking portal.",
  };
  await updateWaitlist(waitlistId, body);
};

export default handleCancelWaitlist;
