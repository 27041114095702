/*
 * Description: This start form is used to obtain the Patient Demographic Information needed for the scheduler system.
 *              QDoc can fetch this data when a patient logs in into qdoc system and then send the data to the patientlist.
 */
import { Container, TextField, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
//redux
import { setUserData } from "../Store/UserSlice";
import { useDispatch } from "react-redux";

//Constants
import {
  confirmButtonColor,
  darkBlue,
  red,
} from "../constants/ColourConstants";
import CustomButton from "../constants/CustomButton";
import * as Yup from "yup";
import HealthCardForm from "./PatientRegistration/healthCardForm";
import { provinceLengths } from "./PatientRegistration/provinceData";
import { getToken } from "../api/utils/authToken";

const style = {
  typographyHeader: {
    color: darkBlue,
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "Arial",
    textAlign: "center",
  },
  typographyError: {
    color: red,
    fontSize: 14,
    fontFamily: "Arial",
  },
};

function RegisterPatientForm() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    async function callPatientAPI() {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
    }
    callPatientAPI();
  }, []);

  async function checkPatientExist(values) {
    setLoading(true);

    await fetch(
      `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/search-patients?phn=${values.phin}&dob=${values.dob}&regNumber=${values.regNumber}&userId=${user.attributes["sub"]}`,
      {
        method: "GET",
        headers: {
          Accept: "*/*",
          Authorization: getToken(),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.length > 0) {
          const patientName = `Name: ${data[0].demographics.lastName}, ${data[0].demographics.firstName}.`;

          Swal.fire({
            title: "Patient profile found!",
            html: `<div>${patientName}</div>`,
            footer: data[0].dynamoPatient
              ? "This health card number is already registered in this account."
              : "You can now book an appointment.",
            confirmButtonText: data[0].dynamoPatient
              ? "Return to Home"
              : "Add Patient",
            denyButtonText: `Try Again`,
            showDenyButton: true,
            icon: "success",
            confirmButtonColor: confirmButtonColor,
          }).then(async (result) => {
            if (result.isConfirmed) {
              if (!data[0].dynamoPatient) {
                const requestBody = {
                  patientId: data[0].patientId,
                  phin: values.phin,
                  firstName: data[0].demographics.firstName,
                  lastName: data[0].demographics.lastName,
                  userId: user.attributes["sub"],
                  userEmail: user.attributes["email"],
                };
                await addPatientCard(requestBody);
              }
              navigate("/");
            } else {
              formik.setFieldValue("dob", JSON.parse(values.dob));
            }
          });
        } else {
          Swal.fire({
            title: "Patient profile not found!",
            text: "Please fill out the upcoming form to add the patient.",
            icon: "warning",
            denyButtonText: `Try Again`,
            showDenyButton: true,
            confirmButtonColor: confirmButtonColor,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/createPatientForm");
            } else {
              formik.setFieldValue("dob", JSON.parse(values.dob));
            }
          });
        }
      });
    setLoading(false);
  }

  async function addPatientCard(requestBody) {
    await fetch(
      `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/save-data`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        body: JSON.stringify(requestBody),
      }
    ).then((response) => {
      return response.json();
    });
  }

  const validationSchema = Yup.object({
    phin: Yup.string()
      .when("healthCardProvince", {
        is: (province) => !!province && !!provinceLengths[province],
        then: Yup.string()
          .matches(/^[a-zA-Z0-9]+$/, "Must be number or alphanumeric")
          .test(
            "exactLength",
            "Your health card number must be exactly the specified length for the selected province",
            function (value) {
              const province = this.parent.healthCardProvince;
              return value?.length === provinceLengths[province];
            }
          ),
      })
      .required("Required"),
    dob: Yup.string().required("Required").nullable(),
    healthCardProvince: Yup.string().required("Required"),
    regNumber: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Must be number or alphanumeric")
      .when("healthCardProvince", {
        is: "MB",
        then: Yup.string().required("Required"),
      }),
  });

  const formik = useFormik({
    initialValues: {
      phin: "",
      dob: "",
      regNumber: "",
      healthCardProvince: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.dob = JSON.stringify(values.dob);
      values.healthCard = true;
      checkPatientExist(values);
      dispatch(setUserData(values));
    },
  });

  if (!loading)
    return (
      <>
        <Container maxWidth="md" sx={{ height: "100%", mt: 5 }}>
          <Box
            sx={{ m: 3, p: 3, background: "#F2F4F6", borderRadius: "16px" }}
            boxShadow={10}
          >
            <form className="form" onSubmit={formik.handleSubmit}>
              <div>
                <Typography sx={style.typographyHeader}>
                  Check For Active Patient Profile
                </Typography>
                <HealthCardForm formik={formik} userData={""} />
                <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    id="dob"
                    label="Date of Birth*"
                    inputFormat="YYYY-MM-DD"
                    disableFuture
                    openTo="year"
                    views={["year", "month", "day"]}
                    value={formik.values.dob || null}
                    onChange={(value) =>
                      formik.setFieldValue("dob", value ? value.$d : null)
                    }
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        sx={{ m: 1 }}
                        {...params}
                        variant="filled"
                        error={formik.touched.dob && Boolean(formik.errors.dob)}
                        helperText={formik.touched.dob && formik.errors.dob}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Typography sx={style.typographyError}>
                  Please call the clinic at{" "}
                  <a href="tel:431-222-2273">431-222-2273</a> if you do not have
                  a health card number.
                </Typography>
                <Grid alignItems="center">
                  <CustomButton
                    sx={{ marginX: 2 }}
                    onClick={formik.handleSubmit}
                    label="Find Profile"
                  ></CustomButton>
                  {/* <CustomButton
                    sx={{ marginX: 2 }}
                    onClick={() => {
                      dispatch(setUserData(""));
                      navigate("/createPatientForm");
                    }}
                    label="No Health Card"
                  ></CustomButton> */}
                  <CustomButton
                    sx={{ marginX: 2 }}
                    onClick={() => {
                      navigate("/");
                    }}
                    label="Cancel"
                  ></CustomButton>
                </Grid>
              </div>
            </form>
          </Box>
        </Container>
      </>
    );

  return (
    <div className="App">
      <Box
        sx={{
          display: `${loading ? "flex" : "none"}`,
          width: "center",
          height: 600,
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    </div>
  );
}

export default RegisterPatientForm;
