import React, { useCallback, useEffect } from "react";
import { Box, Typography, Grid, Chip } from "@mui/material";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

// Constants
import { darkBlue } from "../../constants/ColourConstants";
import { formatTimeWithPeriod } from "../Common/FormatTime";
import CustomButton from "../../constants/CustomButton";

const style = {
  typographyButton: {
    color: darkBlue,
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "Arial",
    textAlign: "center",
  },
};

const gridStyles = {
  ".MuiDataGrid-columnHeaders": {
    color: darkBlue,
    fontSize: 17,
  },
  "& .MuiTablePagination-displayedRows": {
    margin: "0 !important",
  },
};
function PastAppointments() {
  const cutOffDate = moment().format("2023-06-30");
  const navigate = useNavigate();
  const { state } = useLocation();

  const columns = [
    { field: "date", headerName: "Date/Time", width: 300 },
    {
      field: "status",
      headerName: "Status",
      width: 300,
      renderCell: (params) => {
        const appointment = params.row;
        const isMissedPaymentRequired = state.unpaidAppointments.includes(
          appointment.id
        );
        const isArrivedOrBeforeCutoff =
          appointment.arrived || appointment.date < cutOffDate;
        if (isArrivedOrBeforeCutoff) {
          return <Chip color="success" label="Completed" />;
        } else if (state.paidAppointments.includes(appointment.id)) {
          return (
            <Chip color="success" label="Paid No-show Fee" variant="outlined" />
          );
        } else if (isMissedPaymentRequired) {
          return <Chip color="error" label="Missed Appointment" />;
        } else {
          return <Chip color="warning" label="In progress" />;
        }
      },
    },
    { field: "type", headerName: "Type", width: 300 },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const rows = state?.patientAppointments?.map((appointment) => ({
    id: appointment.appointmentId,
    date: `${appointment.date} @ ${formatTimeWithPeriod(
      appointment.startTime
    )}`,
    type: "In-person appointment",
    arrived: appointment.appointmentDetails?.arrived,
  }));

  const getRowSpacing = useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 7,
      bottom: params.isLastVisible ? 0 : 7,
    };
  }, []);

  return (
    <Box sx={{ margin: "1rem" }}>
      <Typography variant="h2" sx={style.typographyButton}>
        Appointment History
      </Typography>
      <div style={{ height: 650, width: "100%", padding: "1%" }}>
        <DataGrid
          components={{
            Toolbar: GridToolbar,
          }}
          sx={gridStyles}
          getRowSpacing={getRowSpacing}
          rows={rows || []}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </div>
      <Grid item xs={6}>
        <CustomButton
          label="Back"
          onClick={() => {
            navigate("/");
          }}
          variant="contained"
        ></CustomButton>
      </Grid>
    </Box>
  );
}

export default PastAppointments;
