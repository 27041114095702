/*
 * Project Title: QDoc Online Scheduler System - Footer Component: Used in all pages
 * Created: 2022-07-15
 * Updated: 2022-08-16
 * Developers: Reynald Maala, Mihir Maniya
 */
import {
  Box,
  createTheme,
  Link,
  Paper,
  ThemeProvider,
  Typography
} from "@mui/material";
const theme = createTheme();
function Copyright() {
  return (
    <Typography variant="body2" color="#FFFFFF" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.wpgminorillness.com/">
        The Minor Illness & Injury Clinic
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
function Footer() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Paper
          sx={{
            marginTop: "calc(10% + 60px)",
            width: "100%",
            position: "relative",
            bottom: 0,
          }}
          component="footer"
          square
          variant="outlined"
        >
          <Box sx={{ bgcolor: "#005470", p: 6 }}>
            <Typography
              variant="h6"
              color="#FFFFFF"
              align="center"
              gutterBottom
            >
              The Minor Illness & Injury Clinic
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              color="#FFFFFF"
              component="p"
            >
              Our mission is to revolutionize the way that our patients
              experience health care by providing episodic expert care that is
              timely, efficient and client-focused for individuals suffering
              from non-emergent acute illness and injury.
            </Typography>
            <Copyright />
          </Box>
        </Paper>
      </ThemeProvider>
    </>
  );
}
export default Footer;
