// Material UI
import {
  Autocomplete,
  Box,
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControl,
  FormHelperText,
  Typography,
  Tooltip,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { useFormik } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import ExtractMedicationsHistoryAllergies from "./ExtractMedicationsHistoryAllergies";

// React
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";
import { selectProviders } from "../Store/ProvidersSlice";
import { selectPharmacies } from "../Store/PharmaciesSlice";

//components
import {
  confirmButtonColor,
  darkBlue,
  boxGrey,
  borderGrey,
} from "../constants/ColourConstants";
import CustomButton from "../constants/CustomButton";
import { getToken } from "../api/utils/authToken";

const style = {
  backgroundBox: {
    m: 3,
    p: 3,
    background: boxGrey,
    borderRadius: "16px",
    borderStyle: "solid",
    borderColor: borderGrey,
    borderWidth: "1px",
  },
  typographyHeader: {
    color: darkBlue,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Arial",
    textAlign: "center",
  },
};

const provinces = [
  "AB",
  "BC",
  "MB",
  "NB",
  "NL",
  "NS",
  "NT",
  "NU",
  "ON",
  "PE",
  "QC",
  "SK",
  "YT",
];

const EditPatient = () => {
  const [loading, setLoading] = useState(true);
  const doctors = useSelector(selectProviders);
  const contacts = useSelector(selectPharmacies);
  const { state } = useLocation();
  const { allergies, patientNotes } = ExtractMedicationsHistoryAllergies(
    state.paperChartNote
  );

  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * Sends an update patient request to Accuro based on form values.
   *
   * @param {object} body Request data to be sent.
   * @return {boolean} Request result.
   */
  async function updatePatient(body) {
    setLoading(true);

    const data = await fetch(
      `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/update-patients?patientId=${state.patientId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getToken(),
        },
        body: JSON.stringify(body),
      }
    ).then((response) => {
      return response.json();
    });
    setLoading(false);
    return data;
  }

  const validationSchema = Yup.object().shape(
    {
      firstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      lastName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Required"),
      middleName: Yup.string().max(20, "Must be 20 characters or less"),

      sex: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
      city: Yup.string().required("Required"),
      postalCode: Yup.string()
        .matches(
          /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
          "Invalid postal code"
        )
        .required("Required"),
      province: Yup.string().required("Required"),
      phoneNumber: Yup.string()
        .matches(
          /^(\+?1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})?$|^$/,
          "Invalid phone number"
        )
        .min(10, "Must be 10 digits")
        .max(10, "Must be 10 digits")
        .when("landlineNumber", {
          is: (landlineNumber) => !landlineNumber,
          then: Yup.string().required(
            "At least one method of contact is required."
          ),
        }),
      landlineNumber: Yup.string()
        .matches(
          /^(\+?1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})?$|^$/,
          "Invalid landline number"
        )
        .min(10, "Must be 10 digits")
        .max(10, "Must be 10 digits")
        .when("phoneNumber", {
          is: (phoneNumber) => !phoneNumber,
          then: Yup.string().required(
            "At least one method of contact is required."
          ),
        }),
      allergies: Yup.string().max(100, "Must be less than 100 characters"),
      patientNotes: Yup.string().max(100, "Must be less than 100 characters"),
    },
    [
      ["phoneNumber", "landlineNumber"],
      ["landlineNumber", "phoneNumber"],
    ]
  );

  const formik = useFormik({
    initialValues: {
      firstName: state.demographics.firstName,
      lastName: state.demographics.lastName,
      middleName: state.demographics.middleName,
      dateOfBirth: state.demographics.birthday,
      sex: state.demographics.genderId,
      address: state.demographics.addresses[0]["street"],
      city: state.demographics.addresses[0]["city"],
      postalCode: state.demographics.addresses[0]["postalZip"],
      province: state.demographics.addresses[0]["locationId"],
      phoneNumber: state.demographics.phones
        .filter((item) => item.contactType === "CellPhone")[0]
        ?.["number"].replace(/\D/g, ""),
      landlineNumber: state.demographics.phones
        .filter((item) => item.contactType === "HomePhone")[0]
        ?.["number"].replace(/\D/g, ""),
      email: state.demographics.email.address,
      phin: state.demographics.healthCard.phn,
      regNumber: state.registrationNumber,
      healthCardProvince: state.demographics.healthCard.locationId,
      pharmacy: state.pharmacyContactId,
      doctor: state.familyProviderId,
      clinic: state.officeProviderId,
      allergies: allergies,
      patientNotes: patientNotes,
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      values.dynamoId = state.dynamoId;
      const data = await updatePatient(values);
      if (!data.error && data.message !== "Internal server error") {
        Swal.fire({
          title: "Update Successful",
          text: "You have updated the patient.",
          icon: "success",
          confirmButtonColor: confirmButtonColor,
        });
        navigate("/");
      } else {
        Swal.fire({
          title: "Oops! Please try again!",
          text: data.error ?? data.message,
          icon: "error",
          confirmButtonColor: confirmButtonColor,
        });
      }
    },
  });

  const sexes = ["Male", "Female", "Other"];

  return (
    <>
      {doctors.allProviders && contacts.allPharmacies ? (
        <>
          <div>
            <Container maxWidth="lg">
              <Box sx={style.backgroundBox}>
                <Typography sx={style.typographyHeader}>
                  EDIT PATIENT
                </Typography>
                <Box component="form" onSubmit={formik.handleSubmit}>
                  <Grid container spacing={1} sx={{ mb: 2, mt: 2 }}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        id="firstName"
                        name="firstName"
                        type="text"
                        variant="filled"
                        label="First Name*"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.firstName &&
                          Boolean(formik.errors.firstName)
                        }
                        helperText={
                          formik.touched.firstName && formik.errors.firstName
                        }
                        inputProps={{
                          maxLength: 15,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        id="middleName"
                        name="middleName"
                        type="text"
                        label="Middle Name"
                        variant="filled"
                        value={formik.values.middleName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.middleName &&
                          Boolean(formik.errors.middleName)
                        }
                        helperText={
                          formik.touched.middleName && formik.errors.middleName
                        }
                        inputProps={{
                          maxLength: 20,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        id="lastName"
                        name="lastName"
                        type="text"
                        label="Last Name*"
                        variant="filled"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.lastName &&
                          Boolean(formik.errors.lastName)
                        }
                        helperText={
                          formik.touched.lastName && formik.errors.lastName
                        }
                        inputProps={{
                          maxLength: 20,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <Grid item xs={12} sm={6}>
                      <Tooltip
                        enterTouchDelay={50}
                        title="Please contact the clinic to edit"
                      >
                        <TextField
                          fullWidth
                          id="dateOfBirth"
                          name="dateOfBirth"
                          label="Date of Birth*"
                          type="text"
                          variant="filled"
                          value={formik.values.dateOfBirth}
                          disabled
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth variant="filled">
                        <InputLabel id="sex-select-label">Sex*</InputLabel>
                        <Select
                          fullWidth
                          id="sex"
                          labelId="sex-select-label"
                          name="sex"
                          variant="filled"
                          value={formik.values.sex}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.sex && Boolean(formik.errors.sex)
                          }
                        >
                          {sexes.map((sex, i) => (
                            <MenuItem key={i + 1} value={i + 1}>
                              {sex}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText
                          error={
                            formik.touched.sex && Boolean(formik.errors.sex)
                          }
                        >
                          {formik.touched.sex && formik.errors.sex}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="address"
                        name="address"
                        type="text"
                        label="Address*"
                        variant="filled"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.address &&
                          Boolean(formik.errors.address)
                        }
                        helperText={
                          formik.touched.address && formik.errors.address
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="city"
                        name="city"
                        type="text"
                        label="City*"
                        variant="filled"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                        helperText={formik.touched.city && formik.errors.city}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="postalCode"
                        name="postalCode"
                        type="text"
                        label="Postal Code*"
                        variant="filled"
                        value={formik.values.postalCode}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.postalCode &&
                          Boolean(formik.errors.postalCode)
                        }
                        helperText={
                          formik.touched.postalCode && formik.errors.postalCode
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth variant="filled">
                        <InputLabel
                          id="province-select-label"
                          error={
                            formik.touched.province &&
                            Boolean(formik.errors.province)
                          }
                        >
                          Province*
                        </InputLabel>
                        <Select
                          labelId="province-select-label"
                          id="province"
                          name="province"
                          value={formik.values.province}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.province &&
                            Boolean(formik.errors.province)
                          }
                        >
                          {provinces.map((province) => (
                            <MenuItem key={province} value={province}>
                              {province}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText
                          error={
                            formik.touched.province &&
                            Boolean(formik.errors.province)
                          }
                        >
                          {formik.touched.province && formik.errors.province}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="phoneNumber"
                        name="phoneNumber"
                        type="text"
                        label="Cell Phone Number"
                        variant="filled"
                        value={formik.values.phoneNumber}
                        onChange={(e) =>
                          /^[0-9]{0,10}$/.test(e.target.value) &&
                          formik.setFieldValue("phoneNumber", e.target.value)
                        }
                        error={
                          formik.touched.phoneNumber &&
                          Boolean(formik.errors.phoneNumber)
                        }
                        helperText={
                          formik.touched.phoneNumber &&
                          formik.errors.phoneNumber
                        }
                        inputProps={{
                          maxLength: 10,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="landlineNumber"
                        name="landlineNumber"
                        type="text"
                        label="Landline Number"
                        variant="filled"
                        value={formik.values.landlineNumber}
                        onChange={(e) =>
                          /^[0-9]{0,10}$/.test(e.target.value) &&
                          formik.setFieldValue("landlineNumber", e.target.value)
                        }
                        error={
                          formik.touched.landlineNumber &&
                          Boolean(formik.errors.landlineNumber)
                        }
                        helperText={
                          formik.touched.landlineNumber &&
                          formik.errors.landlineNumber
                        }
                        inputProps={{
                          maxLength: 10,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        type="text"
                        label="Best Email to Contact You"
                        variant="filled"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={4}>
                      <Tooltip
                        enterTouchDelay={50}
                        title="Please contact the clinic to edit"
                      >
                        <TextField
                          fullWidth
                          id="phin"
                          name="phin"
                          type="text"
                          label="Health Card Number*"
                          variant="filled"
                          value={formik.values.phin ?? ""}
                          disabled
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Tooltip
                        enterTouchDelay={50}
                        title="Please contact the clinic to edit"
                      >
                        <TextField
                          fullWidth
                          id="regNumber"
                          name="regNumber"
                          type="text"
                          label="Registration Number"
                          variant="filled"
                          value={formik.values.regNumber ?? ""}
                          disabled
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Tooltip
                        enterTouchDelay={50}
                        title="Please contact the clinic to edit"
                      >
                        <TextField
                          fullWidth
                          id="healthCardProvince"
                          name="healthCardProvince"
                          type="text"
                          label="Health Card Province*"
                          variant="filled"
                          value={formik.values.healthCardProvince ?? ""}
                          disabled
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  {doctors.allProviders.length &&
                  contacts.allPharmacies.length ? (
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          options={contacts.allPharmacies}
                          getOptionLabel={(option) =>
                            option.length === 0 ? "" : option.contactName
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.contactId === value?.contactId
                          }
                          value={
                            formik.values.pharmacy !== "" &&
                            formik.values.pharmacy !== null
                              ? contacts.allPharmacies.find((pharmacy) => {
                                  return (
                                    pharmacy.contactId ===
                                    formik.values.pharmacy
                                  );
                                })
                              : null
                          }
                          onChange={(e, value) =>
                            formik.setFieldValue(
                              "pharmacy",
                              value ? value.contactId : null
                            )
                          }
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              {...props}
                              key={option.contactId}
                            >
                              {option.contactName}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Preferred Pharmacy"
                              variant="filled"
                              fullWidth
                            />
                          )}
                          disabled={contacts.allPharmacies.length === 0}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          options={doctors.uniqueProviders}
                          getOptionLabel={(option) =>
                            option.length === 0 ? "" : option.fullName
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          value={
                            formik.values.doctor !== "" &&
                            formik.values.doctor !== null
                              ? doctors.uniqueProviders.find((doctor) => {
                                  return doctor.id === formik.values.doctor;
                                })
                              : null
                          }
                          onChange={(e, value) =>
                            formik.setFieldValue(
                              "doctor",
                              value ? value.id : null
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Family Doctor"
                              variant="filled"
                              fullWidth
                            />
                          )}
                          disabled={doctors.uniqueProviders.length === 0}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Box>
                      Loading pharmacies and doctors...
                      <CircularProgress color="inherit" size={16} />
                    </Box>
                  )}
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        id="allergies"
                        name="allergies"
                        type="text"
                        label="Allergies"
                        variant="filled"
                        multiline
                        rows={2}
                        value={formik.values.allergies}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.allergies &&
                          Boolean(formik.errors.allergies)
                        }
                        helperText={
                          formik.touched.allergies && formik.errors.allergies
                        }
                        inputProps={{
                          maxLength: 100,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        id="patientNotes"
                        name="patientNotes"
                        type="text"
                        label="Notes (Include any relevant medications and medical history)"
                        placeholder="Include any relevant medications and medical history"
                        variant="filled"
                        multiline
                        rows={3}
                        value={formik.values.patientNotes}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.patientNotes &&
                          Boolean(formik.errors.patientNotes)
                        }
                        helperText={
                          formik.touched.patientNotes &&
                          formik.errors.patientNotes
                        }
                        inputProps={{
                          maxLength: 100,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid alignItems="center">
                    <CustomButton
                      sx={{ marginX: 2 }}
                      onClick={formik.handleSubmit}
                      label="Update"
                    ></CustomButton>

                    <CustomButton
                      sx={{ marginX: 2 }}
                      onClick={() => {
                        navigate("/");
                      }}
                      label="Cancel"
                    ></CustomButton>
                  </Grid>
                </Box>
              </Box>
            </Container>
          </div>
        </>
      ) : (
        <div className="App">
          <Box
            sx={{
              display: `${loading ? "flex" : "none"}`,
              width: "center",
              height: 600,
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        </div>
      )}
    </>
  );
};

export default EditPatient;
