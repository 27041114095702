/*
 * Project Title: QDoc Online Scheduler System - Header Component: Used in all pages
 * Created: 2022-07-15
 * Updated: 2023-08-01
 * Developers: Reynald Maala, Mihir Maniya
 */
// Amplify
import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

// Material UI
import {
  AppBar,
  Box,
  createTheme,
  Grid,
  Link,
  ThemeProvider,
  Toolbar,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
} from "@mui/material";
import { Logout } from "@mui/icons-material";

// Constants
import {
  confirmButtonColor,
  darkBlue,
  red,
  white,
} from "../constants/ColourConstants";
import logo from "./../images/tmiic.png";

// Frameworks
import Swal from "sweetalert2";
import { getToken } from "../api/utils/authToken";

const Theme = createTheme({
  palette: {
    primary: {
      main: white,
    },
  },
});

function Header() {
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const getPatientsByUserId = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const userId = user.attributes["sub"];
    const patients = await fetch(
      `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/user-info?userId=${userId}`,
      {
        method: "GET",
        headers: {
          Accept: "*/*",
          Authorization: getToken(),
        },
      }
    ).then((response) => response.json());
    return patients.Items;
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    handleMenuClose();
    navigate("/");
    await Auth.signOut();
  };

  const handleChangePassword = async () => {
    handleMenuClose();
    navigate("/changePassword");
  };

  const handleDeleteAccount = async () => {
    const patients = await getPatientsByUserId();
    handleMenuClose();
    if (patients.length) {
      Swal.fire({
        title: "Cannot delete user account",
        text: "Please delete all patient cards before deleting the user account",
        icon: "error",
        confirmButtonColor: confirmButtonColor,
      });
    } else {
      const result = await Swal.fire({
        title: "Are you sure you want to delete this account?",
        text: "Deleting your account is not reversible. You will lose access to your account and all data associated with it.",
        showDenyButton: true,
        confirmButtonText: "Delete Account",
        denyButtonText: `Cancel`,
        confirmButtonColor: red,
        denyButtonColor: confirmButtonColor,
      });
      if (result.isConfirmed) {
        await Auth.deleteUser();
      }
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ThemeProvider theme={Theme}>
        <AppBar
          sx={{
            position: "static",
          }}
        >
          <Toolbar sx={{ mt: 1 }}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item>
                <Link href="/">
                  <Box
                    component="img"
                    sx={{ height: 50, width: "auto", mr: -8 }}
                    alt="Logo"
                    src={logo}
                  />
                </Link>
              </Grid>
            </Grid>
            <IconButton onClick={handleMenuClick} size="small" sx={{ ml: 2 }}>
              <Avatar />
            </IconButton>
            <Menu
              id="account-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
            >
              <MenuItem sx={{ color: darkBlue }} onClick={handleChangePassword}>
                Change Password
              </MenuItem>
              <MenuItem sx={{ color: red }} onClick={handleDeleteAccount}>
                Delete Account
              </MenuItem>
              <Divider />
              <MenuItem sx={{ color: darkBlue }} onClick={handleSignOut}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Sign Out
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </Box>
  );
}

export default Header;
