/**
 * Gets the already booked appointments for the selected date.
 *
 * @param {string} today The date today in "YYYY-MM-DD" format.
 * @param {string} tomorrow tomorrow date in "YYYY-MM-DD" format.
 * @return {array} List of booked appointments.
 */

import { getToken } from "./utils/authToken";

export const getBookedAppointments = async (today, tomorrow) => {
  return await fetch(
    `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/get-appointments?startDate=${today}&endDate=${tomorrow}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        Authorization: getToken(),
      },
    }
  ).then((response) => response.json());
};

/**
 * Gets all the available appointments from Accuro from todays date to a year from now.
 *
 * @param {string} today The date today in "YYYY-MM-DD" format.
 * @param {string} tomorrow tomorrow date in "YYYY-MM-DD" format.
 * @return {array} List of providers suggestions.
 */
export const getSuggestions = async (today, tomorrow) => {
  return await fetch(
    `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/get-applied-suggestions?startDate=${today}&endDate=${tomorrow}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        Authorization: getToken(),
      },
    }
  ).then((response) => response.json());
};

/**
 * * Sends an appointment booking request to Accuro, based on form values.
 *
 * @param {object} requestBody Request data to be sent.
 */
export const bookAppointment = async (requestBody) => {
  return await fetch(
    `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/create-appointment`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      body: JSON.stringify(requestBody),
    }
  ).then((response) => response.json());
};

/**
 * Sends the patient a text message with the appointment information.
 *
 * @param {object} body Request data to be sent.
 */
export const sendConfirmation = async (body) => {
  return await fetch(
    `https://${process.env.REACT_APP_ENV_MESSAGES_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/messages`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    }
  ).then((response) => response.json());
};

/**
 * Creates a new appointment record in DynamoDB.
 *
 * @param {object} body Request data to be sent.
 */
export const createDynamoAppointment = async (requestBody) => {
  await fetch(
    `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/create-dynamo-appointment`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      body: JSON.stringify(requestBody),
    }
  );
};

/**
 * Get patient's appointments history from Accuro using appointmentId.
 *
 * @param {object} body Request data to be sent.
 */
export const appointmentsHistory = async (appointmentId) => {
  const response = await fetch(
    `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/appointment-history?appointmentId=${appointmentId}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
      },
    }
  );
  const data = await response.json();
  return data ? data : [];
};

/**
 * Get patient's appointments from Accuro using patientId.
 *
 * @param {object} body Request data to be sent.
 */
export const getPatientAppointments = async (patientId) => {
  const response = await fetch(
    `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/appointments-by-patientID?patient=${patientId}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        Authorization: getToken(),
      },
    }
  );

  if (response.ok) {
    const data = await response.json();
    const sortedAppointments = data.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );

    return sortedAppointments;
  }
};
