/*
 * Project Title: TMIIC Online Scheduler Systems
 * Created: 2022-07-15
 * Updated: 2022-12-08
 * Developers: Taiwo Omoleye, Fergal Ingoldsby, and Arvine Gopez, Reynald Maala, Mihir Maniya
 */
import {
  Authenticator,
  Heading,
  Text,
  Image,
  useTheme,
  View,
} from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import awsconfig from "./aws-exports";
//redux
import { Provider } from "react-redux";
import store from "./Store/Store";
//Components
import "./App.css";
import Footer from "./components/footer";
import Header from "./components/header";
import Home from "./components/home";
import PatientBanner from "./components/PatientBanner";
import PastAppointments from "./components/PastAppointments/PastAppointments";
import RegisterPatientForm from "./components/startForm";
import Maintenance from "./components/Testing/Maintenance"; // eslint-disable-line no-unused-vars
import PatientBooking from "./components/Booking/Booking";
import CreatePatientForm from "./components/createPatientForm";
import EditPatient from "./components/editPatient";
import logo from "./images/tmiic.png";
import background from "./images/signInBackground.png";
import ChangePassword from "./components/changePassword";
Amplify.configure(awsconfig);

const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="TMIIC logo" src={logo} height="4rem" />
      </View>
    );
  },
  Footer() {
    const { tokens } = useTheme();
    return (
      <View
        padding={tokens.space.small}
        style={{
          fontSize: 15,
          textAlign: "center",
        }}
      >
        <address>
          For technical support, email us at{" "}
          <a href="mailto:bookings.techsupport@qdoc.ca">
            bookings.techsupport@qdoc.ca
          </a>
        </address>

        <Text color={tokens.colors.neutral[80]}>
          &copy; {new Date().getFullYear()} The Minor Illness & Injury Clinic
        </Text>
      </View>
    );
  },
  VerifyUser: {
    Header() {
      return (
        <Heading level={3}>
          Please select the correct email address below to verify your account
        </Heading>
      );
    },
  },
  ConfirmVerifyUser: {
    Header() {
      return <Heading level={3}>Enter code sent to your email address</Heading>;
    },
    Footer() {
      return (
        <Text>
          Please check your junk/spam folder if you have not received a code.
        </Text>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      return <Heading level={3}>We Emailed You</Heading>;
    },
    Footer() {
      return (
        <Text>
          Please check your junk/spam folder if you have not received a code.
        </Text>
      );
    },
  },
};

function App() {
  return (
    // <Maintenance />
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Authenticator components={components}>
        <div className="App">
          <Provider store={store}>
            <BrowserRouter>
              <Header />
              <PatientBanner />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="PastAppointments" element={<PastAppointments />} />
                <Route path="startForm" element={<RegisterPatientForm />} />
                <Route
                  path="createPatientForm"
                  element={<CreatePatientForm />}
                />
                <Route path="patientBooking" element={<PatientBooking />} />
                <Route path="editPatient" element={<EditPatient />} />
                <Route path="changePassword" element={<ChangePassword />} />
                <Route path="*" element={<p>There's nothing here: 404!</p>} />
              </Routes>
            </BrowserRouter>
            <Footer />
          </Provider>
        </div>
      </Authenticator>
    </div>
  );
}
export default App;
