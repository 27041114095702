import Swal from "sweetalert2";
import { confirmButtonColor } from "../../constants/ColourConstants";

const Confirm = (formik, title, message) => {
  const confirm = async () => {
    const result = Swal.fire({
      title: title,
      text: message,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
      confirmButtonColor: confirmButtonColor,
    });
    if ((await result).isConfirmed) {
      formik.handleSubmit();
    }
  };

  return confirm;
};

export default Confirm;
