import Swal from "sweetalert2";
import { red, confirmButtonColor } from "../../constants/ColourConstants";
import handleCancelWaitlist from "./handleCancelWaitlist";

const confirmCancellation = async (
  patientId,
  waitlistId,
  referralDate,
  requestDate,
  today
) => {
  const result = await Swal.fire({
    title: "Are you sure you want to remove yourself from the waitlist?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: confirmButtonColor,
    cancelButtonColor: red,
    confirmButtonText: "Yes, cancel it",
    cancelButtonText: "No, keep it",
  });

  if (result.isConfirmed) {
    await handleCancelWaitlist(
      patientId,
      waitlistId,
      referralDate,
      requestDate,
      today
    );

    Swal.fire({
      title: "Waitlist canceled!",
      text: "Successfully removed from the waitlist.",
      icon: "success",
      confirmButtonColor: confirmButtonColor,
    }).then(() => {
      window.location.reload();
    });
  }
};

export default confirmCancellation;
