import * as React from "react";
import LockClockIcon from "@mui/icons-material/LockClock";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
import CustomButton from "../constants/CustomButton";
import { disableGrey } from "../constants/ColourConstants";

export default function LockedCardsDialog() {
  return (
    <Tooltip
      enterTouchDelay={50}
      title={
        <React.Fragment>
          <Typography color="inherit">Active Appointment </Typography>
          <em>
            {
              "If you have missed your appointment, please note that the clinic has a policy of charging a"
            }
          </em>{" "}
          <u>
            {" "}
            <b>{"no-show fee"}</b> {" of $75.00"}
          </u>
          .{" "}
          {
            "We kindly request that you call the clinic to inform them about the missed appointment."
          }
        </React.Fragment>
      }
    >
      <span>
        <CustomButton
          label="Payment Required"
          sx={{
            backgroundColor: disableGrey,
            fontSize: 12,
            "&:hover": {
              backgroundColor: disableGrey,
            },
            ml: 1,
          }}
          startIcon={<LockClockIcon />}
        />
      </span>
    </Tooltip>
  );
}
