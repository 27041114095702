// Redux
import { createSlice } from "@reduxjs/toolkit";

export const patientAppointmentsSlice = createSlice({
  name: "patientAppointments",
  initialState: {},
  reducers: {
    setPatientAppointmentsData: (state, action) => {
      return action.payload;
    },
  },
});

export const selectPatientAppointments = (state) => state.patientAppointments;

export const { setPatientAppointmentsData } = patientAppointmentsSlice.actions;

export default patientAppointmentsSlice.reducer;
