/**
 * Gets feature flag by name
 *
 * @param {string} name
 */

export const getFeatureFlagByName = async (name) => {
  return await fetch(
    `https://${process.env.REACT_APP_ENV_FEATURE_FLAG}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/getFeatureFlagByName/${name}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
      },
    }
  ).then((response) => response.json());
};
