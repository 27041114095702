import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//MUI
import { Box, Grid, Typography } from "@mui/material";

//COMPONENTS
import {
  getBookedAppointments,
  getSuggestions,
} from "../../api/AppointmentEndpointRequests";
import { fetchWaitlistByPatientID } from "../../api/WaitlistEndpointRequests";
import BookingForm from "./BookingForm";
import BookingFormik from "./BookingFormik";
import Loading from "./Loading";
import { Slots } from "./Slots";

// Redux
import { useSelector } from "react-redux";
import {
  selectProviders,
  selectProvidersLoading,
} from "../../Store/ProvidersSlice";

//Constants
import {
  darkBlue,
  boxGrey,
  grey,
  borderGrey,
} from "../../constants/ColourConstants";
import CustomButton from "../../constants/CustomButton";

const style = {
  typographyHeader: {
    color: darkBlue,
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "Arial",
    textAlign: "center",
    marginTop: "15px",
  },
  typographyBody: {
    color: grey,
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "Arial",
    textAlign: "center",
  },
  backgroundBox: {
    m: 3,
    p: 3,
    background: boxGrey,
    borderRadius: "16px",
    borderStyle: "solid",
    borderColor: borderGrey,
    borderWidth: "1px",
  },
};

const Booking = () => {
  const { state } = useLocation();
  const [highlightedDays, setHighlightedDays] = useState([]);
  const [suggestionNames, setSuggestionNames] = useState([]);
  const [bookedAppointmentTimes, setBookedAppointmentTimes] = useState([]);
  const [providers, setProviders] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOnWaitlist, setIsOnWaitlist] = useState(false);
  const [waitlistData, setWaitlistData] = useState([]);
  const doctors = useSelector(selectProviders);
  const doctorsLoading = useSelector(selectProvidersLoading);
  const adult =
    Math.floor(
      (new Date() - new Date(state.demographics.birthday).getTime()) /
        3.15576e10
    ) >= 18;
  const today = moment(new Date()).format("YYYY-MM-DD");
  const tomorrow = moment(today).add(1, "day").format("YYYY-MM-DD");
  const navigate = useNavigate();

  useEffect(() => {
    const getBookings = async () => {
      setLoading(true);
      await getBookingInformation();
      await checkPatientWaitlistStatus(state.patientId);
      setLoading(false);
    };
    getBookings();
  }, [doctorsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const getBookingInformation = async () => {
    const bookedAppointments = await getBookedAppointments(today, tomorrow);
    const providersReturn = doctors.allProviders;

    const pediatricianIds = providersReturn
      .filter((doc) => doc.typeId === 28)
      .map((doc) => doc.id);
    setProviders(providersReturn);
    setBookedAppointmentTimes(bookedAppointments);

    if (providersReturn.length) {
      const suggestions = await getSuggestions(today, tomorrow);
      const pediatricianSuggestions = suggestions.filter((s) =>
        pediatricianIds.includes(s.providerId)
      );
      const adultSuggestions = suggestions.filter(
        (s) => !pediatricianIds.includes(s.providerId)
      );
      const filteredSuggestions = adult
        ? adultSuggestions
        : pediatricianSuggestions;

      const allSlots = Slots("", "", bookedAppointments, filteredSuggestions);
      fetchHighlightedDays(allSlots);

      setSuggestionNames([...new Set(filteredSuggestions.map((s) => s.name))]);
      setFilteredSuggestions(filteredSuggestions);
    }
  };
  const checkPatientWaitlistStatus = async (patientId) => {
    const data = await fetchWaitlistByPatientID(patientId);
    if (data.contents && data.contents.length > 0) {
      const activeWaitlist = data.contents.filter(
        (waitlist) => waitlist.caseState === "Active"
      );
      if (activeWaitlist.length > 0) {
        setWaitlistData(activeWaitlist);
        setIsOnWaitlist(true);
      }
    }
  };

  const formik = BookingFormik(
    state,
    providers,
    getBookingInformation,
    waitlistData
  );

  /**
   * Gets the appointment time slots.
   *
   * @param {Array} suggestions Appointment suggestions
   * @return {Array} Appointment session time slots
   */
  const slots = useMemo(() => {
    return Slots(
      formik.values.date,
      formik.values.reason,
      bookedAppointmentTimes,
      filteredSuggestions
    );
  }, [
    formik.values.date,
    formik.values.reason,
    bookedAppointmentTimes,
    filteredSuggestions,
  ]);

  /**
   * Sets the highlighted days state based on all available slots.
   *
   * @param {number} slots All available slots
   */
  const fetchHighlightedDays = (slots) => {
    if (slots.length === 0) return;
    const days = slots.map((slot) => {
      return {
        day: parseInt(slot.date.substring(8, 10)),
        month: parseInt(slot.date.substring(5, 7)),
      };
    });
    setHighlightedDays(days);
  };

  return (
    <>
      {<Loading loading={loading || doctorsLoading} />}

      {filteredSuggestions.length === 0 && !loading && !doctorsLoading && (
        <Box sx={style.backgroundBox}>
          <Typography variant="h5" sx={style.typographyHeader}>
            There are no available booking slots.
          </Typography>
          <Typography sx={style.typographyBody}>
            There are no providers currently available so please contact the
            clinic directly to book.
          </Typography>
          <Typography sx={style.typographyBody}>
            Phone: +1 431 222 2273
          </Typography>
        </Box>
      )}
      {filteredSuggestions.length > 0 && !loading && !doctorsLoading && (
        <BookingForm
          formik={formik}
          activeWaitlist={isOnWaitlist}
          suggestionNames={suggestionNames}
          highlightedDays={highlightedDays}
          slots={slots}
          refreshAppointments={getBookingInformation}
        />
      )}
      <Grid item xs={6}>
        <CustomButton
          label="Back"
          onClick={() => {
            navigate("/");
          }}
          variant="standard"
        ></CustomButton>
      </Grid>
    </>
  );
};

export default Booking;
