export const provinces = [
  "AB",
  "BC",
  "MB",
  "NB",
  "NL",
  "NS",
  "NT",
  "NU",
  "ON",
  "PE",
  "QC",
  "SK",
  "YT",
];

export const provinceLengths = {
  BC: 10,
  AB: 9,
  SK: 9,
  MB: 9,
  ON: 10,
  NB: 9,
  NS: 10,
  PE: 8,
  NL: 12,
  NT: 8,
  YT: 9,
  NU: 9,
};
