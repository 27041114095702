import { getToken } from "./utils/authToken";

/**
 * Gets providers data.
 *
 * @return {array} List of providers
 */
export const getProviders = async () => {
  const response = await fetch(
    `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/get-doctors`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        Authorization: getToken(),
      },
    }
  );
  const data = await response.json();
  return data ? data : [];
};

export const uniqueProviders = (doctor, index, duplicate) => {
  return index === duplicate.findIndex((d) => d.fullName === doctor.fullName);
};

/**
 * Gets Contacts data.
 *
 * @return {array} List of Contacts - type pharmacy
 */
export const getContacts = async () => {
  const response = await fetch(
    `https://${process.env.REACT_APP_ENV_ACCURO_GATEWAY}.execute-api.ca-central-1.amazonaws.com/${process.env.REACT_APP_ENV}/get-pharmacy-contacts`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",
        Authorization: getToken(),
      },
    }
  );
  const data = await response.json();
  return data ? data : [];
};
